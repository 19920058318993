<template>
  <div class="loan_item">
    <div class="inner">
      <h3>{{ $t('contactUs.title') }}</h3>
      <div class="left_cell">
        <p>{{ $t('contactUs.p1') }}</p>
        <p>{{ $t('contactUs.p2') }}</p>
        <p>{{ $t('contactUs.p3') }}</p>
        <p>{{ $t('contactUs.p4') }}</p>
        <p>{{ $t('contactUs.p5') }}</p>
        <p>{{ $t('contactUs.p6') }}</p>
        <p>{{ $t('contactUs.p7') }}</p>
      </div>
      <div class="right_cell">
        <img src="@/assets/images/loanImg/bn_e.png" alt="">
      </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.desc {
  color: #333;
  font-size: 18px;
  line-height: 22px;
}
.desc2 {
  margin-top: 10px;
  margin-bottom: 30px;
}
.loan_item{
  padding-bottom: 60px;
  .inner{
    width: 1200px;
    margin: 0 auto;
    overflow: hidden;
    h3{
      font-size: 36px;
      color: #333333;
    }
    .left_cell{
      float: left;
      width: 50%;
      padding-top: 39px;
      color: #999999;
      p {
        font-size: 16px;
        line-height: 38px;
      }
    }
    .right_cell{
      float: left;
      width: 50%;
      padding-top: 19px;
      img{
        width: 579px;
        height: 422px;
      }
    }
  }

}
</style>
